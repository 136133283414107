<template>
  <div>
    <div class="card">
      <Toolbar class="mb-4">
        <template #start>
          <Button
            label="Parameterobjekt hinzufügen"
            icon="pi pi-plus"
            class="p-button-success mr-2"
            @click="openNew(null)"
          />
          <Button
            label="Parameterobjekt löschen"
            icon="pi pi-trash"
            class="p-button-danger"
            @click="confirmDeleteSelected"
            :disabled="!selectedIOs || !selectedIOs.length"
          />
        </template>

        <template #end>
          <FileUpload
            mode="basic"
            accept="image/*"
            :maxFileSize="1000000"
            label="Import"
            chooseLabel="Import"
            class="mr-2 d-inline-block"
          />
          <Button
            label="Export"
            icon="pi pi-upload"
            class="p-button-help"
            @click="exportCSV($event)"
          />
        </template>
      </Toolbar>

      <DataTable
        ref="dt"
        :value="io_type"
        v-model:selection="selectedIOs"
        dataKey="_id"
        :loading="loading"
        :paginator="true"
        :rows="100"
        :filters="filters"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 50, 100]"
        currentPageReportTemplate="Zeige {first} bis {last} von {totalRecords} Einträgen"
        responsiveLayout="scroll"
      >
        <template #loading> Daten werden geladen... bitte warten </template>
        <template #header>
          <div
            class="
              table-header
              d-flex flex-column flex-md-row jc-md-between
            "
          >
            <h5 class="mb-2 m-md-0 as-md-center">Icons</h5>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                placeholder="suchen..."
              />
            </span>
          </div>
        </template>

        <Column selectionMode="multiple" :exportable="false"></Column>
        <Column field="icon" header="Icon" :sortable="false">
          <template #body="slotProps">
            <Button
              v-if="slotProps.data.icon"
              :icon="slotProps.data.icon"
              class="p-button-rounded p-button-primary mr-2"
              style="font-size: 1.7rem"
            />
          </template>
        </Column>
        <Column field="label" header="Bezeichnung" :sortable="true"></Column>
        <Column :exportable="false" style="min-width: 8rem">
          <template #body="slotProps">
            <Button
              icon="pi pi-pencil"
              class="p-button-rounded p-button-success mr-2"
              @click="editIO(slotProps.data)"
            />
            <Button
              icon="pi pi-trash"
              class="p-button-rounded p-button-warning"
              @click="confirmDeleteIO(slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>
    </div>

    <Dialog
      v-model:visible="ioDialog"
      :style="{ width: '450px' }"
      header="Eintrag bearbeiten"
      :modal="true"
      class="p-fluid"
      @hide="hideDialog"
    >
      <div class="field col">
        <div class="field text-center">
          <span :class="ioDialogData.icon + ' mif-5x'"></span>
        </div>
      </div>
      <div class="field">
        <label for="categoryStatus" class="mb-3">Kategorie</label>
        <Dropdown
          id="categoryStatus"
          v-model="ioDialogData.key"
          :options="config_io.types_category"
          optionLabel="label"
          optionValue="value"
          placeholder="Wetterstation, Heizung,..."
          @change="onCategoryChange"
        >
        </Dropdown>
      </div>
      <div class="field">
        <Tree :value="io_subtype" selectionMode="single" v-model:selectionKeys="selectedParameter" :expandedKeys="expandedKeys" @nodeSelect="onNodeSelect"></Tree>
      </div>
      <div class="formgrid grid mb-2">
        <div class="col">
        <Button
            label="Parameter hinzufügen"
            icon="pi pi-plus"
            class="p-button-success mr-2"
            @click="openNewParameter(null)"
          />
        </div>
        <div class="col">
        <Button
            label="Parameter duplizieren"
            icon="pi pi-copy"
            class="p-button-info"
            @click="openNewParameter(selectedKey)"
            :disabled="!selectedKey || Array.isArray(selectedKey.children)"
          />
        </div>
        <div class="col">
        <Button
            label="Parameter löschen"
            icon="pi pi-trash"
            class="p-button-danger"
            @click="deleteParameter"
            :disabled="!selectedKey || Array.isArray(selectedKey.children)"
          />
        </div>
      </div>
      <div v-if="selectedKey && !Array.isArray(selectedKey.children)">
        <div class="field">
          <label for="parameterName">Parameterbezeichnung</label>
          <InputText
            id="parameterName"
            v-model.trim="selectedKey.label"
          />
        </div>
        <div class="field">
          <label for="parameterName">Variablen-Name im SPS-Programm (Groß/Kleinschreibung!)</label>
          <InputText
            id="variableName"
            v-model.trim="selectedKey.key"
          />
        </div>
        <div class="formgrid grid mb-2">
          <div class="field col">
            <label for="price">Einheit</label>
            <Dropdown
              id="unit"
              v-model="selectedKey.unit"
              :options="config_io.types_unit"
              optionLabel="label"
              optionValue="label"
            >
              <template #value="slotProps">
                <div>
                  <span> {{ slotProps.value }}</span>
                </div>
              </template>
              <template #option="slotProps">
                <div>
                  <span>{{ slotProps.option.label }}</span>
                </div>
              </template>
            </Dropdown>
          </div>
          <div class="field col">
            <label for="price">Faktor</label>
            <Dropdown
              id="factor"
              v-model="selectedKey.factor"
              :options="io_value_factor"
              optionLabel="label"
              optionValue="value"
            >
              <template #value="slotProps">
                <div>
                  <span> {{ slotProps.value }}</span>
                </div>
              </template>
              <template #option="slotProps">
                <div>
                  <span>{{ slotProps.option.label }}</span>
                </div>
              </template>
            </Dropdown>
          </div>
        </div>
      </div>

      <template #footer>
        <Button
          label="Abbrechen"
          icon="pi pi-times"
          class="p-button-text"
          @click="hideDialog"
        />
        <Button
          label="Speichern"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveIO"
        />
      </template>
    </Dialog>

    <Dialog
      v-model:visible="deleteIoDialog"
      :style="{ width: '450px' }"
      header="Bestätigung"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="ioDialogData"
          >Soll der Eintrag wirklich aus der Datenbank gelöscht werden: <br />
          <span style="font-family: LogFileFont; color: yellow">{{
            ioDialogData.label
          }}</span>
          ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Nein"
          icon="pi pi-times"
          class="p-button-text"
          @click="deleteIoDialog = false"
        />
        <Button
          label="Ja"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteIO"
        />
      </template>
    </Dialog>

    <Dialog
      v-model:visible="deleteIosDialog"
      :style="{ width: '450px' }"
      header="Bestätigung"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="ioDialogData"
          >Sollen die Einträge wirklich aus der Datenbank gelöscht werden?</span
        >
      </div>
      <template #footer>
        <Button
          label="Nein"
          icon="pi pi-times"
          class="p-button-text"
          @click="deleteIosDialog = false"
        />
        <Button
          label="Ja"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSelectedIOs"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import helpers from "@/helpers";
import { FilterMatchMode } from "primevue/api";
// import ProductService from "../helpers";
// import mainService from "@/services/mainService.js";
import SocketioService from "@/services/socketioService.js";
// import socketioService from "@/services/socketioService.js";

export default {
  name: "config_io_icons",
  data() {
    return {
      loading: false,
      ioDialog: false,
      deleteIoDialog: false,
      deleteIosDialog: false,
      ioDialogData: {},
      config_io: null,
      io_type: null,
      io_subtype: [],
      selectedIOs: null,
      selectedParameter: null,
      selectedKey: null,
      expandedKeys: {},
      filters: {},
      submitted: false,
      intval_Value: null,
      elementIndex: -1,
      subtypeIndex: -1,
      subtypeCnt: 0,
      io_value_factor: [
        { label: "1", value: 1 },
        { label: "10", value: 10 },
        { label: "100", value: 100 },
        { label: "1000", value: 1000 },
        { label: "10000", value: 10000 },
        { label: "100000", value: 100000 },
        { label: "1000000", value: 100000 },
      ],
    };
  },
  created() {
    this.initFilters();
  },
  mounted() {
    // this.productService.getProducts().then((data) => (this.products = data));
    this.loading = true;
    this.getParameter();
    this.registerSocketIoEvents();
  },
  beforeUnmount() {
    this.unregisterSocketIoEvents();
  },
  methods: {
    expandNode(node) {
        this.expandedKeys[node.key] = true;
        if (node.children && node.children.length) {
            for (let child of node.children) {
                this.expandNode(child);
            }
        }
    },
    expandAll() {
        for (let node of this.io_subtype) {
            this.expandNode(node);
        }

        this.expandedKeys = {...this.expandedKeys};
    },
    onNodeSelect(node) {
      // for (let [key] of Object.entries(this.selectedIOs)) {
      //     if (key.match(/^\d+/)) {
      //         let dataId = {};
      //         dataId.id = key;
      //         // this.ioDialogData.data.push(dataId);
      //         console.log(key, value);
      //     }
      // }
      this.selectedKey = node;
      console.log(this.selectedKey);
    },
    onCategoryChange(category) {
      const _cat = helpers.findIndexByName(this.config_io.types_category, category.value);
      console.log(this.config_io.types_category[_cat]);
      this.ioDialogData.label = this.config_io.types_category[_cat].label;
      this.ioDialogData.data = this.config_io.types_category[_cat].value;
      this.getChildren(this.config_io.types_category[_cat].value);
    },
    resetDialogData() {
      this.ioDialogData = {
        // group: {},
      };
      this.elementIndex = -1;
      this.selectedParameter = null;
      this.selectedKey = null;
      this.io_subtype = [];
      this.subtypeCnt = 0;
    },
    getParameterConfigIO() {
      SocketioService.getServerData("config", 'io', (err, response) => {
        this.config_io = response;
        this.io_type = response.types_parameters;
        // console.log(this.io_type);
        // this.io_type.sort((a, b) => a.label.localeCompare(b.label));
        this.loading = false;
      });
      // SocketioService.getConfigIO((response) => {
      //   this.config_io = response;
      //   this.io_type = response.types_icons;
      //   this.loading = false;
      //   // this.io_department = response.types_department;
      //   // this.io_types = response.types_io;
      //   // this.io_unit = response.types_unit;
      //   // this.io_server = response.types_server;
      //   // this.io_icons = response.types_icons;
      // });
    },
    getParameter() {
      this.getParameterConfigIO();
    },
    registerSocketIoEvents() {
      SocketioService.socket.on("set_config_io_type_icons_result", (data) => {
        if (data !== null) {
          this.getParameter();
          // document not changed
          if (
            data.acknowledged &&
            data.matchedCount > 0 &&
            data.upsertedCount === 0 &&
            data.modifiedCount === 0
          ) {
            this.$toast.add({
              severity: "info",
              summary: "Eintrag unverändert",
              detail:
                "modifiziert: " +
                data.modifiedCount +
                " , keine Änderung notwendig ",
              life: 3000,
            });
            // document updated
          } else if (
            data.acknowledged &&
            data.matchedCount > 0 &&
            data.upsertedCount === 0 &&
            data.modifiedCount > 0
          ) {
            this.$toast.add({
              severity: "success",
              summary: "Eintrag geändert",
              detail:
                "modifiziert: " +
                data.modifiedCount +
                " , alle Änderungen wurden gespeichert ",
              life: 3000,
            });
            // document deleted
          } else if (data.acknowledged && data.deletedCount > 0) {
            this.$toast.add({
              severity: "success",
              summary: "Eintrag gelöscht",
              detail: data.deletedCount + " Einträge erfolgreich gelöscht",
              life: 3000,
            });
            // document created
          } else if (data.acknowledged && data.insertedId !== null) {
            this.$toast.add({
              severity: "success",
              summary: "Eintrag erstellt",
              detail:
                "Eintrag mit Name [" +
                data.insertedId.toString() +
                "] erfolgreich erstellt",
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Eintrag nicht durchgeführt",
              detail:
                "modifiziert: " +
                data.modifiedCount +
                " , Erstellt: " +
                data.upsertedCount +
                " [" +
                data.upsertedId +
                "]",
              life: 3000,
            });
          }
        }
      });
    },
    unregisterSocketIoEvents() {
      SocketioService.socket.removeAllListeners(
        "set_config_io_type_icons_result"
      );
    },
    openNew(ioData) {
      this.resetDialogData();

      if (ioData !== null) {
        const _obj = JSON.parse(JSON.stringify(ioData));
        this.ioDialogData = _obj;
      } else {
        this.ioDialogData.label = "Lüftung";
        this.ioDialogData.key = "ventilation";
        this.ioDialogData.data = this.ioDialogData.key;
        this.ioDialogData.icon = "mif-meter";
        // this.ioDialogData.children = [];
        // this.ioDialogData.children[0] = {};
        // this.ioDialogData.children[0].key = "VARIABLE_" + this.subtypeCnt;
        // this.ioDialogData.children[0].label = "# Neuer Parameter " + this.subtypeCnt;
        // this.ioDialogData.children[0].data = this.ioDialogData.key;
        // this.ioDialogData.children[0].type = "setpoint";
        // this.ioDialogData.children[0].icon = null;
        // this.ioDialogData.children[0].unit = "°C";
        // this.ioDialogData.children[0].factor = 1;
      }
      this.submitted = false;
      this.ioDialog = true;
      this.expandAll();
    },
    openNewParameter(ioData) {
      if (ioData !== null) {
        const _obj = JSON.parse(JSON.stringify(ioData));
        _obj.key = _obj.key + this.io_subtype[0].children.length;
        this.io_subtype[0].children.push(_obj);
      } else {
        let subtype = {};
        this.subtypeCnt++;
        subtype.label = "# Neuer Parameter " + this.subtypeCnt;
        subtype.key = "VARIABLE_" + this.subtypeCnt;
        subtype.data = this.ioDialogData.key;
        subtype.type = "setpoint"
        subtype.icon = null;
        subtype.unit = "°C";
        subtype.factor = 1;
        if (this.io_subtype.length === 0) {
          this.io_subtype[0] = {};
          this.io_subtype[0].children = [];
        }
        this.io_subtype[0].children.push(subtype);
        console.log(subtype);
      }
      this.submitted = false;
      this.ioDialog = true;
      this.expandAll();
    },
    deleteParameter() {
      console.log(this.selectedKey);
      if (this.selectedKey && this.selectedKey.key !== null) {
        const index = helpers.findIndexByTag(this.io_subtype[0].children, 'key', this.selectedKey.key);
        console.log(index);
        console.log(this.io_subtype[0].children[index]);
        if (index > 0) {
          this.io_subtype[0].children.splice(index, 1);
        }
      }
    },
    hideDialog() {
      this.io_subtype = [];
      this.selectedParameter = null;
      this.selectedKey = null;
      this.ioDialog = false;
      this.submitted = false;
      // clearInterval(this.intval_Value);
      this.getParameter();
    },
    saveIO() {
      this.submitted = true;

      if (this.ioDialogData.key) {
        // this.ioDialogData.icon = this.ioDialogData.label;
        // let _index = helpers.findIndexByName(this.io_type, this.ioDialogData.value);
        if (this.elementIndex >= 0) {
          this.ioDialogData.children = this.io_subtype[0].children;
          this.io_type[this.elementIndex] = this.ioDialogData;
          SocketioService.setConfigIOType(this.io_type, "types_parameters");
        } else {
          let _size = Object.keys(this.io_type).length;
          this.ioDialogData.children = this.io_subtype[0].children;
          this.io_type[_size] = this.ioDialogData;
          // console.log(this.io_type);
          SocketioService.setConfigIOType(this.io_type, "types_parameters");
        }
        // clearInterval(this.intval_Value);
        this.ioDialog = false;
        this.resetDialogData();
      }
    },
    getChildren(category) {
      this.io_subtype = [];
      this.elementIndex = helpers.findIndexByTag(this.io_type, 'key', category);
      console.log(this.io_type);
      console.log(category);
      console.log(this.elementIndex);
      if (this.elementIndex >= 0) {
        this.io_subtype[0] = {};
        this.io_subtype[0].label = this.io_type[this.elementIndex].label;
        this.ioDialogData[0].key = this.io_type[this.elementIndex].value;
        this.ioDialogData[0].children = [];
        this.io_subtype[0].children.push(this.io_type[this.elementIndex]);
      }
      console.log(this.io_subtype);
    },
    editIO(ioData) {
      // this.ioDialogData = { ...ioData };
      this.getParameter();
      const _obj = JSON.parse(JSON.stringify(ioData));
      this.elementIndex = helpers.findIndexByTag(this.io_type, 'key', _obj.key);
      // this.io_subtype = [...this.io_type[this.elementIndex]];
      if (this.elementIndex >= 0) {
        this.io_subtype.push(this.io_type[this.elementIndex]);
      }
      this.subtypeCnt = this.io_subtype[0].children.length;
      // console.log(this.io_subtype);
      // this.elementIndex = helpers.findIndexByName(this.io_type, _obj.value);
      this.ioDialogData = _obj;
      this.ioDialog = true;
      this.expandAll();
    },
    confirmDeleteIO(ioData) {
      const _obj = JSON.parse(JSON.stringify(ioData));
      this.ioDialogData = _obj;
      this.deleteIoDialog = true;
    },
    deleteIO() {
      let _index = helpers.findIndexByName(
        this.io_type,
        this.ioDialogData.value
      );
      if (_index >= 0) {
        this.io_type.splice(_index, 1);
        SocketioService.setConfigIOType(this.io_type, "types_icons");
        // SocketioService.removeUser(this.system_user[_index]);
        // this.$toast.add({
        //   severity: "success",
        //   summary: "Successful",
        //   detail: "Product Updated",
        //   life: 3000,
        // });
      } else {
        // this.product.id = this.createId();
        // this.product.code = this.createId();
        // this.product.image = "product-placeholder.svg";
        // this.products.push(this.product);
        // this.$toast.add({
        //   severity: "success",
        //   summary: "Successful",
        //   detail: "Product Created",
        //   life: 3000,
        // });
      }
      this.deleteIoDialog = false;
      this.resetDialogData();
      // this.$toast.add({
      //   severity: "success",
      //   summary: "Successful",
      //   detail: "Product Deleted",
      //   life: 3000,
      // });
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteIosDialog = true;
    },
    deleteSelectedIOs() {
      SocketioService.removeValue(this.selectedIOs);
      this.deleteIosDialog = false;
      this.selectedIOs = null;
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
</style>